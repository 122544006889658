import styled from 'styled-components';
import { Divider } from 'antd';
import { colorMap } from '@utils/colorMap';

const PRODUCT_NAME_THEME = {
  dark: '#007680',
  light: '#0495A2',
};

export const Wrapper = styled.header<{ $isIframe: boolean }>`
  box-sizing: border-box;
  display: ${({ $isIframe }) => ($isIframe ? 'none' : 'flex')};
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  min-height: 55px;
  padding: 0 20px;
  margin-bottom: 5px;
  background-color: ${() => colorMap.backgroundColor};
  * {
    font-family: Open Sans, sans-serif;
  }
  @media (max-width: 480px) {
    padding: 0 5px;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  padding-left: 40px;
  position: relative;
  padding-top: 3px;
  flex: 1;
`;

export const Mid = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 3px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-content: space-between;
  align-items: center;
  padding-top: 3px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LogoLink = styled.a`
  display: block;
  width: 172px;
  height: 32px;
  @media (max-width: 480px) {
    width: 95px;
    height: 18px;
  }
  img {
    display: block;
    max-width: 100%;
  }
`;

export const SimpleLogoLink = styled.a`
  display: block;
  width: 32px;
  height: 32px;
  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
  }
  img {
    display: block;
    max-width: 100%;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ProductName = styled.h2`
  display: inline-block;
  font-size: 23px;
  padding: 0;
  margin: 0 0 0 20px;
  font-weight: 200;
  color: ${() => PRODUCT_NAME_THEME};
  @media (max-width: 480px) {
    font-size: 18px;
    margin: 0 0 0 12px;
  }
`;

export const PulseLabel = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #82bc09;
  margin: 0 5px;
  text-transform: uppercase;
`;

export const ProductLabel = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: #3e3e3e;
  margin-left: 5px;
`;

// hard code for background.
// See https://conviva.invisionapp.com/d/main#/console/18500627/409494279/inspect
export const StyledDivider = styled(Divider)`
  height: 40px;
  background: ${() => colorMap.lineSeparator};
`;
