import React from 'react';
import { get } from 'lodash';
import { PRODUCTS, PRODUCT_DISPLAY_NAMES_NEW_NAV } from '@constants/products';
import {
  APP_AI_ALERTS,
  ECO_TRENDS,
  APP_USER_TIMELINE,
  REAL_TIME,
  PROMPT,
} from '@subApps/navApp/hooks/useData/usePulse5Features/featureNames';
import App from '@resources/icons/product/App.svg';
import { useValidConfig } from '../../hooks/useValidConfig';
import { Menu } from '../../components/Menu';
import { useConfigWithSavedDashboard } from '../Dashboard/hooks';

const config = {
  product: PRODUCTS.APP,
  icon: <App />,
  displayName: PRODUCT_DISPLAY_NAMES_NEW_NAV[PRODUCTS.APP],
  groups: [
    [
      {
        id: 'app-experience',
        category: PRODUCTS.APP,
        displayName: 'Trends',
        url: '/app/app-insights/dashboard/app-experience',
        valid: ({ features }) => get(features, ECO_TRENDS, false),
      },
      {
        id: 'app-user-timeline',
        category: PRODUCTS.APP,
        displayName: 'Users',
        url: '/app/app-insights/dashboard/user-timeline',
        valid: ({ features }) => get(features, APP_USER_TIMELINE, false),
      },
      {
        id: 'app-real-time',
        category: PRODUCTS.APP,
        displayName: 'Real-Time',
        url: '/app/app-insights/dashboard/real-time',
        valid: ({ features }) => get(features, REAL_TIME, false),
      },
      {
        id: 'app-ai-alerts',
        category: PRODUCTS.APP,
        displayName: 'AI Alerts',
        url: '/app/app-insights/dashboard/ai-alerts',
        valid: ({ features }) => {
          return get(features, APP_AI_ALERTS, false);
        },
      },
      {
        id: 'ai-cohorts-eco',
        category: PRODUCTS.APP,
        displayName: 'AI Cohorts',
        url: '/app/app-insights/dashboard/ai-cohorts',
        valid: ({ features }) => {
          return get(features, APP_AI_ALERTS, false);
        },
      },
      {
        id: 'app-prompt',
        category: PRODUCTS.APP,
        displayName: 'PromptAI',
        url: '/app/app-insights/dashboard/prompt',
        valid: ({ features }) => {
          return get(features, PROMPT, false);
        },
      },
      {
        id: 'app-customizable-dashboard',
        category: PRODUCTS.APP,
        displayName: 'Custom Dashboards',
        url: '/app/custom-dashboards?source=app',
        valid: () => true,
        target: '_blank',
      },
    ],
    [
      // {
      //   id: 'sensor-integration',
      //   category: PRODUCTS.APP,
      //   displayName: 'Sensor Integration',
      //   url: '/app/integration/sensor-integration',
      //   valid: ({ features }) => get(features, ECO_TRENDS, false),
      // },
      {
        id: 'data-modeling',
        category: PRODUCTS.ACTIVATION,
        displayName: 'Activation',
        url: '/app/activation/home',
        valid: ({ features }) => get(features, ECO_TRENDS, false),
      },
    ],
  ],
};

const APP = () => {
  const validConfig = useValidConfig(config);
  const menuConfig = useConfigWithSavedDashboard(
    PRODUCTS.APP,
    validConfig,
    'app-customizable-dashboard',
  );

  return <Menu config={menuConfig} />;
};

export default APP;
